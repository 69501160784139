export class MtlsCertificate {
  id!: string;
  description!: string;
  contractId!: string;
  host!: string;
  validationStatus!: string;
  user!: string;
  creationDate!: string;
  storageProvider!: string;
  storageProviderId!: string;
  expirationDate!: string;
  groupId!: string;

  constructor(mtlsCertificate: Partial<MtlsCertificate>) {
    Object.assign(this, mtlsCertificate);
  }
}
