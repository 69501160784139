import React, { FC, useContext } from 'react';
import { BdsButton, BdsAlert, BdsAlertHeader, BdsAlertBody, BdsAlertActions } from 'blip-ds/dist/blip-ds-react';
import '../../styles.scss';
import { useContentLocalizer } from '../../../../hooks/useContentLocalizer';
import { localization } from './localization';
import MtlsContext from '../../../../contexts/MtlsContext';
import { showToast } from '../../../../api/CommonApi';

const RemoveCertificate: FC = () => {
  const {
    removeCertificateAlert,
    closeRemoveCertificateAlert,
    mtlsCertificateApi,
    tenantId,
    setCertificateIdToRemove,
    certificateIdToRemove,
    setMtlsCertificate,
    mtlsCertificate,
  } = useContext(MtlsContext);
  const content = useContentLocalizer(localization);

  const handleDeleteCertificate = async () => {
    await mtlsCertificateApi.deleteMtlsCertificate(tenantId, certificateIdToRemove);
    const responseStatus = mtlsCertificateApi.getStatus();
    if (responseStatus == 'success') {
      closeRemoveCertificateAlert();
      mtlsCertificate.splice(
        mtlsCertificate.findIndex((e) => e.id === certificateIdToRemove),
        1,
      );
      setMtlsCertificate(mtlsCertificate);
      setCertificateIdToRemove('');
      showToast({
        type: 'success',
        message: content.successRemovedCertificate,
      });
    } else {
      showToast({
        type: 'danger',
        message: content.failRemoveCeritificate,
      });
    }
  };

  return (
    <BdsAlert open={removeCertificateAlert}>
      <BdsAlertHeader variant="warning" icon="warning">
        {content.action}
      </BdsAlertHeader>
      <BdsAlertBody>{content.confirmMessage}</BdsAlertBody>
      <BdsAlertActions>
        <BdsButton variant="secondary" onClick={closeRemoveCertificateAlert}>
          {content.cancel}
        </BdsButton>
        <BdsButton variant="secondary" onClick={handleDeleteCertificate}>
          {content.disable}
        </BdsButton>
      </BdsAlertActions>
    </BdsAlert>
  );
};

export default RemoveCertificate;
