import React, { useContext, useEffect, useState } from 'react';
import {
  BdsGrid,
  BdsPaper,
  BdsTypo,
  BdsTable,
  BdsTableHeader,
  BdsTableBody,
  BdsTableCell,
  BdsTableRow,
  BdsChipTag,
  BdsTableTh,
  BdsButtonIcon,
} from 'blip-ds/dist/blip-ds-react';
import { ColorChipTag } from 'blip-ds/dist/types/components/chip-tag/chip-tag';
import MtlsContext from '../../../../contexts/MtlsContext';
import RemoveCertificate from '../RemoveCertificate';
import { MtlsCertificate } from '../../../../models/MtlsCertificate';
import { BlipLoadingComponent } from '../../../../components/BlipLoading/BlipLoadingComponent';
import { useContentLocalizer } from '../../../../hooks/useContentLocalizer';
import { localization } from './localization';
import { formatDate } from '../../../../utils/date';
import { RequestStatus } from '../../../../shared/Requests';
import { showToast } from '../../../../api/CommonApi';

interface MtlsCertificateheader {
  name: string;
  url: string;
  expiration: string;
  status: string;
  actions: string;
}

interface statusValidation {
  status: string;
  color: string;
  text: string;
}

enum Status {
  VALID = 'valid',
  INVALID = 'invalid',
  UNDERVALIDATION = 'underValidation',
}

const ListCertificates: React.FC = () => {
  const {
    openRemoveCertificateAlert,
    mtlsCertificateApi,
    tenantId,
    mtlsCertificate,
    setMtlsCertificate,
    certificateIdToRemove,
    setCertificateIdToRemove,
  } = useContext(MtlsContext);
  const [isLoading, setLoading] = useState(false);
  const content = useContentLocalizer(localization);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);

        const mtlsCertificateData = await mtlsCertificateApi.getMtlsCertificates(tenantId);
        if (mtlsCertificateApi.getStatus() == RequestStatus.FAILURE) {
          showToast({
            type: 'danger',
            message: content.errorToast,
          });
        }
        setMtlsCertificate(mtlsCertificateData);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [certificateIdToRemove]);

  const heading: MtlsCertificateheader[] = [
    content.name,
    content.url,
    content.expiration,
    content.status,
    content.actions,
  ];

  const statusValidation = [
    { status: Status.VALID, color: 'success', text: content.validationStatus.valid },
    { status: Status.INVALID, color: 'disabled', text: content.validationStatus.invalid },
    { status: Status.UNDERVALIDATION, color: 'default', text: content.validationStatus.underValidation },
  ];

  const chipTagColor = (status: string): ColorChipTag => {
    const color = statusValidation.find(
      (item: statusValidation) => item.status.toLowerCase() === status.toLowerCase(),
    )?.color;
    return color ? (color as ColorChipTag) : 'default';
  };

  const chipTagText = (status: string): string => {
    const text = statusValidation.find(
      (item: statusValidation) => item.status.toLowerCase() === status.toLowerCase(),
    )?.text;
    return text ? text : content.validationStatus.underValidation;
  };

  const handleDeleteCertificateAlert = (id: string) => {
    setCertificateIdToRemove(id);
    openRemoveCertificateAlert();
  };

  return (
    <BdsPaper>
      <BdsGrid padding="3">
        <BdsGrid xxs="12" direction="column">
          <BdsGrid margin="b-2" direction="column">
            <BdsTypo variant="fs-24" bold="bold" margin={false}>
              {content.title}
            </BdsTypo>
            <BdsTypo variant="fs-16">{content.subtitle}</BdsTypo>
          </BdsGrid>
          {isLoading ? (
            <BlipLoadingComponent align="center" />
          ) : (
            <BdsTable>
              <BdsTableHeader>
                <BdsTableRow>
                  {heading.map((item, index) => {
                    return <BdsTableTh key={index}>{item}</BdsTableTh>;
                  })}
                </BdsTableRow>
              </BdsTableHeader>
              <BdsTableBody>
                {mtlsCertificate?.map((row: MtlsCertificate, index) => {
                  return (
                    <BdsTableRow key={index} data-testid={row.id}>
                      <BdsTableCell title={row.description}>
                        <BdsTypo variant="fs-14">{row.description}</BdsTypo>
                      </BdsTableCell>
                      <BdsTableCell type="custom" title={row.host}>
                        <div style={{ width: '300px' }}>
                          <BdsTypo variant="fs-14" noWrap={true}>
                            {row.host}
                          </BdsTypo>
                        </div>
                      </BdsTableCell>
                      <BdsTableCell>
                        <BdsTypo variant="fs-14">{formatDate(new Date(row.expirationDate), content.locale)}</BdsTypo>
                      </BdsTableCell>

                      <BdsTableCell type="custom">
                        <BdsChipTag color={chipTagColor(row.validationStatus)}>
                          {chipTagText(row.validationStatus)}
                        </BdsChipTag>
                      </BdsTableCell>
                      <BdsTableCell type="custom">
                        <BdsButtonIcon
                          onClick={() => {
                            handleDeleteCertificateAlert(row.id);
                          }}
                          size="short"
                          variant="secondary"
                          icon="trash"
                        ></BdsButtonIcon>
                      </BdsTableCell>
                    </BdsTableRow>
                  );
                })}
              </BdsTableBody>
            </BdsTable>
          )}
          <RemoveCertificate />
        </BdsGrid>
      </BdsGrid>
    </BdsPaper>
  );
};

export default ListCertificates;
