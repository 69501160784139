export const localization = {
    pt: {
        locale: 'pt-BR',
        successRemovedCertificate: 'Certificado removido com sucesso',
        cancel: 'Cancelar',
        disable: 'Deletar',
        caution: 'Atenção',
        confirmMessage: 'Deseja realmente deletar esse certificado? Esta ação nao pode ser desfeita!',
        failRemoveCeritificate: 'Falha ao tentar deletar certificado.',
    },
    en: {
        locale: 'en-US',
        successRemovedCertificate: 'Certificate removed successfully',
        cancel: 'Cancel',
        disable: 'Delete',
        caution: 'Caution',
        confirmMessage: 'Do you really want to delete the certificate? This action can\'t be undone',
        failRemoveCeritificate: 'Failed when trying to delete certificate.',
    },
    es: {
        locale: 'es-ES',
        successRemovedCertificate: 'Certificado eliminado exitosamente',
        cancel: 'Cancelar',
        disable: 'Borrar',
        caution: 'Atención',
        confirmMessage: '¿Realmente desea borrar este certificado? ¡Esta acción no se puede deshacer!',
        failRemoveCeritificate: 'Error al intentar borrar el certificado.',
    },
};
